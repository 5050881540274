export const getRandomIndex = (array) => {
  return Math.floor(Math.random() * array.length);
}

export const randomProjectApi = async () => {
  try {
    const projectFetch = await fetch('https://api.publicapis.org/random')
    const projectData = await projectFetch.json()
    return projectData
  } catch (error) {
    console.log(error)
  }
}

export const persuasiveTopics = [
  { topic: "Should tattoos still be considered 'unprofessional?''", stances: ["Yes", "No"]},
  { topic: "Should zoos and animal theme parks (such as Sea World) be closed?", stances: ["Yes", "No"] },
  { topic: "Should it be okay to own exotic animals as pets?", stances: ["Yes", "No"] },
  { topic: "Should organ donation be optional or mandated for all?", stances: ["Optional", "Mandated"]},
  { topic: "Should prisoners have the right to vote?", stances: ["Yes", "No"]},
  { topic: "Should U.S. military funding be increased or decreased?", stances: ["Increased", "Decreased"]},
  { topic: "Should soda and candy be banned from school campuses?", stances: ["Yes", "No"] },
  { topic: "Should tobacco products be completely banned in America?", stances: ["Yes", "No"] },
  { topic: "Should the U.S. have open borders?", stances: ["Yes", "No"] },
  { topic: "Should children be told to believe in Santa Claus and the Tooth Fairy?", stances: ["Yes", "No"] },
  { topic: "Nature vs. nurture—which is the most powerful influence on a person’s character?", stances: ["Nature", "Nurture"] },
  { topic: "Are grades an accurate representation of learning?", stances: ["Yes", "No"] },
  { topic: "Is homeschooling beneficial or harmful to children?", stances: ["Beneficial", "Harmful"] },
  { topic: "Should the police use rubber bullets instead of real bullets?", stances: ["Rubber", "Real"] },
  { topic: "Should the federal minimum wage be increased?", stances: ["Yes", "No"] },
  { topic: "Is capitalism a harmful or beneficial economic system?", stances: ["Harmful", "Beneficial"] },
  { topic: "Do romantic movies and books glorify an unrealistic idea of love and lead to heartbreak?", stances: ["Yes", "No"] },
  { topic: "Is creating films and documentaries about criminals glorifying them and inspiring some to become criminals themselves?", stances: ["Yes", "No"] },
  { topic: "Should countries be allowed to impose a national religion on their citizens?", stances: ["Yes", "No"] },
  { topic: "Should the electoral college be dismantled?", stances: ["Yes", "No"] },
  { topic: "Should airport screening be more or less stringent?", stances: ["More", "Less"] },
  { topic: "Is the paper media more reliable than digital news sources?", stances: ["Yes", "No"] },
  { topic: "Should software companies be able to have pre-downloaded programs and applications on devices?", stances: ["Yes", "No"] },
  { topic: "Should cyberbullying have the same repercussions as in-person bullying?", stances: ["Yes", "More", "Less"] },
  { topic: "Should stores be able to charge customers who use plastic bags?", stances: ["Yes", "No"] },
  { topic: "Should breeding animals (dogs, cats, etc.) be illegal?", stances: ["Yes", "No"] },
  { topic: "Should all public restrooms be all-gender?", stances: ["Yes", "No"] },
  { topic: "Should the national drinking age be lowered?", stances: ["Yes", "No", "Raised"] },
  { topic: "Should schools offer science courses explicitly about mental health?", stances: ["Yes", "No"] },
  { topic: "Should students be allowed to bring their cell phones to school?", stances: ["Yes", "No"] },
  { topic: "Should college athletes be able to make money off of their merchandise?", stances: ["Yes", "No"] },
  { topic: "Should cooking be taught in schools?", stances: ["Yes", "No"] },
  { topic: "Should the penny be phased out?", stances: ["Yes", "No"] },
  { topic: "Should flag burning as a form of protest be prohibited?", stances: ["Yes", "No"] },
  { topic: "Should students have to be on the honor roll in order to play sports?", stances: ["Yes", "No"] },
  { topic: "Should the U.S. limit the use of natural resources?", stances: ["Yes", "No"] },
  { topic: "Should assisted suicide be legal for people who suffer from terminal illnesses?", stances: ["Yes", "No"] },
  { topic: "Should teenagers be allowed to purchase violent video games?", stances: ["Yes", "No"] },
  { topic: "Should people get drug tested for state aid?", stances: ["Yes", "No"] },
  { topic: "Should the USA have a universal health care system?", stances: ["Yes", "No"] },
  { topic: "Should states have the ability to secede from the U.S.?", stances: ["Yes", "No"] },
  { topic: "Should illegal music and movie downloads be prosecuted?", stances: ["Yes", "No"] },
  { topic: "Do magazines marketed to teenagers send the wrong message?", stances: ["Yes", "No"] },
  { topic: "Should certain T.V. shows have age restrictions?", stances: ["Yes", "No"] },
  { topic: "Should schools allow uncensored songs at school dances?", stances: ["Yes", "No"] },
  { topic: "Should police carry firearms?", stances: ["Yes", "No", "Unloaded"] },
  { topic: "Should it be legal for politicians to accept campaign contributions from corporate lobbyists?", stances: ["Yes", "No"] },
  { topic: "Should public schools teach world religions?", stances: ["Yes", "No"] },
  { topic: "Should the United States spend more on space programs?", stances: ["Yes", "No", "Less"] },
  { topic: "Should athletes be paid less?", stances: ["Yes", "No"] },
  { topic: "Should nuclear power be used?", stances: ["Yes", "No"] },
  { topic: "Should 14 year olds should be allowed to hold jobs?", stances: ["Yes", "No"] },
  { topic: "Should Black Friday sales be allowed to start on Thanksgiving?", stances: ["Yes", "No"] },
  { topic: "Should beauty pageants for children be banned?", stances: ["Yes", "No"] },
  { topic: "Should fracking be legal?", stances: ["Yes", "No"] },
  { topic: "Should schools be required to teach all students how to swim?", stances: ["Yes", "No"] }
]

export const audiences = [
  "Mothers",
  "World Travelers",
  "Moviegoers",
  "Hikers",
  "Scientists",
  "Video Game Players",
  "Foodies",
  "Music Lovers",
  "Basketball Fans",
  "Football Fans",
  "Baseball Fans",
  "Farmers",
  "Bakers",
  "People who ADORE the color yellow",
  "Bicyclists",
  "Racers",
  "Children",
  "Dog Lovers",
  "Cat Lovers",
  "Financial Advisors",
  "Doctors",
  "Board Game Enthusiasts",
  "Wildlife Photographers",
  "Car Buffs",
  "Stormchasers",
  "Urban Photographers",
  "Fashion Designers",
  "Linguists",
  "Artists",
  "Musicians",
  "Book Lovers",
  "Coin Collectors",
  "Stamp Collectors",
  "Animal Lovers",
  "Plant Lovers",
  "Military Families",
  "Party Planners",
  "Newlyweds",
  "Bartenders",
  "Historians",
  "Comedians",
  "Trivia Masters",
  "Knitters",
  "Politicians",
  "The Environmentally Conscious",
  "Students",
  "Dancers",
  "Mythologists",
  "Dinosaur Lovers",
  "Stargazers",
  "Influencers",
  "Fitness Coaches",
  "Professors",
  "Filmmakers",
  "Thespians"
]
